import { useNavigate, useLocation } from "react-router-dom";

const SideBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      className="d-flex flex-column flex-shrink-0 box-shadow"
      style={{ width: "280px", marginTop: "15px", padding: "10px" }}
    >
      <ul
        className="nav nav-pills flex-column mb-auto"
        style={{ cursor: "pointer" }}
      >
        <li className="nav-item">
          <div
            className={
              location.pathname === "/admin"
                ? "nav-link active"
                : "nav-link link-dark"
            }
            onClick={() => navigate("/admin")}
          >
            Admin
          </div>
        </li>
        <li className="nav-item">
          <div
            className={
              location.pathname.includes("/admin/organizations")
                ? "nav-link active"
                : "nav-link link-dark"
            }
            onClick={() => navigate("/admin/organizations")}
          >
            Organizations
          </div>
        </li>
        <li className="nav-item">
          <div
            className={
              location.pathname.includes("/admin/projects")
                ? "nav-link active"
                : "nav-link link-dark"
            }
            onClick={() => navigate("/admin/projects")}
          >
            Projects
          </div>
        </li>

        <li>
          <button
            className="btn btn-toggle d-inline-flex align-items-center rounded border-0"
            data-bs-toggle="collapse"
            data-bs-target="#signup-collapse"
            aria-expanded="true"
          >
            Signup
          </button>
          <div className="collapse show" id="signup-collapse">
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li className="nav-item">
                <div
                  className={
                    location.pathname.includes("/admin/signup-domains")
                      ? "nav-link active"
                      : "nav-link link-dark"
                  }
                  onClick={() => navigate("/admin/signup-domains")}
                >
                  Signup Domains
                </div>
              </li>
              <li className="nav-item">
                <div
                  className={
                    location.pathname.includes("/admin/signup-pendings")
                      ? "nav-link active"
                      : "nav-link link-dark"
                  }
                  onClick={() => navigate("/admin/signup-pendings")}
                >
                  Signup Pendings
                </div>
              </li>
              <li className="nav-item">
                <div
                  className={
                    location.pathname.includes("/admin/signup-approvals")
                      ? "nav-link active"
                      : "nav-link link-dark"
                  }
                  onClick={() => navigate("/admin/signup-approvals")}
                >
                  Signup Approvals
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <button
            className="btn btn-toggle d-inline-flex align-items-center rounded border-0"
            data-bs-toggle="collapse"
            data-bs-target="#users-collapse"
            aria-expanded="true"
          >
            Users
          </button>
          <div className="collapse show" id="users-collapse">
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li className="nav-item">
                <div
                  className={
                    location.pathname.includes("/admin/live-users")
                      ? "nav-link active"
                      : "nav-link link-dark"
                  }
                  onClick={() => navigate("/admin/live-users")}
                >
                  Live Users
                </div>
              </li>
              <li className="nav-item">
                <div
                  className={
                    location.pathname.includes("/admin/active-users")
                      ? "nav-link active"
                      : "nav-link link-dark"
                  }
                  onClick={() => navigate("/admin/active-users")}
                >
                  Active Users
                </div>
              </li>
              <li className="nav-item">
                <div
                  className={
                    location.pathname.includes("/admin/portal-admin-users")
                      ? "nav-link active"
                      : "nav-link link-dark"
                  }
                  onClick={() => navigate("/admin/portal-admin-users")}
                >
                  Portal Admin Users
                </div>
              </li>
              <li className="nav-item">
                <div
                  className={
                    location.pathname.includes("/admin/users")
                      ? "nav-link active"
                      : "nav-link link-dark"
                  }
                  onClick={() => navigate("/admin/users")}
                >
                  Users
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default SideBar;
