import { useEffect, useRef, useState } from "react";
import Overlay from "../common/overlays/Overlay";

const Help: React.FC = () => {
  const [showWhatsNew, setShowWhatsNew] = useState<boolean>(false);

  const okButtonRef = useRef<HTMLButtonElement>(null);

  const releases = [
    {
      version: "1.2.2",
      features: [
        {
          title: "Share Location using GPS and using Network Mapped Fields",
        },
        {
          title: "URL Initialization using GPS and using Network Mapped Fields",
        },
        {
          title:
            "Resizing of smaller Camera Images to fit more container white space",
        },
        {
          title: "Help Menu",
        },
        {
          title: "Save last used Year for Multi-year Projects",
        },
        {
          title: "Full Resolution Image Loading for Normal Cameras",
        },
      ],
    },
    {
      version: "1.2.1",
      features: [
        {
          title: "Fixes and Improvements",
        },
      ],
    },
    {
      version: "1.2.0",
      features: [
        {
          title: "Zoom and Pan on Pavement Camera",
        },
        {
          title: "Full Screen Pavement Camera",
        },
        {
          title: "Full Resolution Image Loading for Pavement Camera",
        },
        {
          title:
            "Full Resolution Image Loading for Panoramic Camera when in Full Screen",
        },
      ],
    },
  ];

  useEffect(() => {
    okButtonRef.current?.click();
  }, []);

  const onWhatsNewClicked = () => {
    setShowWhatsNew(true);
  };

  const onWhatsNewCloseClicked = () => {
    setShowWhatsNew(false);
  };

  return (
    <>
      <ul className="dropdown">
        <li>
          <span onClick={onWhatsNewClicked}>What's New</span>
        </li>
        <li>
          <a
            className="link"
            href="https://icc-ims.com/our-software/inform"
            target="_blank"
            rel="noreferrer"
          >
            About Inform
          </a>
        </li>
        <li>
          <a
            className="link"
            href="https://icc-ims.com/our-software"
            target="_blank"
            rel="noreferrer"
          >
            Unify
          </a>
        </li>
        <li>
          <a
            className="link"
            href="https://icc-ims.com/support"
            target="_blank"
            rel="noreferrer"
          >
            Support
          </a>
        </li>
        <li>
          <a
            className="link"
            href="https://icc-ims.com/company/contact-us"
            target="_blank"
            rel="noreferrer"
          >
            Contact us
          </a>
        </li>
      </ul>
      {showWhatsNew && (
        <Overlay>
          <div
            className="box-shadow d-flex flex-column"
            style={{ padding: "40px", borderRadius: "11px" }}
          >
            <div
              className="mb-5"
              style={{
                fontSize: "32px",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              What's New
            </div>
            {releases.map((release, index1) => (
              <div key={index1.toString()} className="mb-3">
                <div
                  className="mb-2"
                  style={{ fontSize: "19px", fontWeight: "600" }}
                >
                  {release.version}
                </div>
                {release.features.map((feature, index2) => (
                  <div
                    key={release.version + "_" + index2.toString()}
                    className="mb-1"
                    style={{ fontSize: "17px", fontWeight: "400" }}
                  >
                    {feature.title}
                  </div>
                ))}
              </div>
            ))}
            <div className="mx-auto mt-4">
              <button
                type="button"
                className="btn btn-primary"
                style={{ width: "250px" }}
                onClick={onWhatsNewCloseClicked}
                ref={okButtonRef}
              >
                OK
              </button>
            </div>
          </div>
        </Overlay>
      )}
    </>
  );
};

export default Help;
