import { useNavigate, useParams } from "react-router-dom";
import BoxContent from "../../components/common/BoxContent";
import { useEffect, useState } from "react";
import Organization from "../../models/organization";
import Project from "../../models/project";
import projectService from "../../services/projectService";
import formHelper from "../../util/formHelper";

const ProjectEdit: React.FC<{
  organization: Organization | undefined;
}> = ({ organization }) => {
  const { id: idParam } = useParams();
  const navigate = useNavigate();

  const [isNew, setIsNew] = useState<boolean>();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [project, setProject] = useState<Project>(new Project());

  useEffect(() => {
    const initEdit = async (idProject: number) => {
      const { data: project } = await projectService.getProjectById(idProject);

      setProject(project);
    };

    const isNew = idParam === "new";
    setIsNew(isNew);

    if (!isNew) {
      const idProject = Number(idParam);
      initEdit(idProject);
    } else {
      // TODO - Create Project
    }
  }, [idParam, organization]);

  const onIsOnlineChanged = () => {
    const newValue = !project.isOnline;

    const newProject = {
      ...project,
      isOnline: newValue,
    };

    setProject(newProject);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    setErrorMessage("");

    try {
      if (isNew) {
        // TODO Create Project
      } else {
        await projectService.updateProject(project);
      }
    } catch (error: any) {
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
      return;
    }

    navigateAway();
  };

  const onCancel = () => {
    navigateAway();
  };

  const navigateAway = () => {
    navigate("/admin/projects");
  };

  return (
    <BoxContent title={(isNew ? "New" : "Edit") + " Project"} useFullWidth>
      <div className="d-flex mb-3">
        <div style={{ fontWeight: "bold", marginRight: "10px" }}>
          Organizaiton
        </div>
        <div>{organization?.organizationName}</div>
      </div>
      <form
        className="input-form"
        onSubmit={onSubmit}
        onKeyDown={formHelper.onEnterKeyDownPreventSubmit}
      >
        <div className="row">
          <div className="mb-3">
            <label htmlFor="projectName" className="form-label">
              Project Name
            </label>
            <input
              type="text"
              className="form-control"
              id="projectName"
              readOnly
              value={project.projectName}
            />
          </div>
          <div className="mb-3">
            <div className="d-flex align-items-center">
              <label className="form-check-label" htmlFor="isOnline">
                Is Online
              </label>
              <div className="form-switch form-switch-right-text">
                <input
                  className="form-check-input form-switch-right-text"
                  type="checkbox"
                  checked={project.isOnline}
                  onChange={onIsOnlineChanged}
                  id="isOnline"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <button type="submit" className="btn btn-primary">
              {isNew ? "Create" : "Save"}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              style={{ marginLeft: "10px" }}
              onClick={onCancel}
            >
              Cancel
            </button>
            <div className="col-md-12 text-center mt-3">
              {errorMessage !== "" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
          </div>
        </div>
      </form>
    </BoxContent>
  );
};

export default ProjectEdit;
