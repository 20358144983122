import ProjectInfo from "../models/projectInfo";
import { useNavigate } from "react-router-dom";
import NavBar from "../components/navbar/NavBar";
import { useEffect, useState } from "react";
import tooltips from "../util/tooltips";
import projectService from "../services/projectService";
import localStorageService from "../services/localStorageService";
import ProjectCollectionInfo from "../models/projectCollectionInfo";
import BoxContent from "../components/common/BoxContent";
import pageHelper from "../util/pageHelper";

const Projects: React.FC = () => {
  // const projects: ProjectInfo[] = [
  //   {
  //     idProject: 1,
  //     projectName: "Project1",
  //     displayName: "Project 1",
  //     year: "2022",
  //     hasMilepostNavigation: false,
  //   },
  //   {
  //     idProject: 2,
  //     projectName: "Project2",
  //     displayName: "Project 2",
  //     year: "2023",
  //     hasMilepostNavigation: false,
  //   },
  // ];

  const [projectCollections, setProjectCollections] = useState<
    ProjectCollectionInfo[]
  >([]);

  const [allProjectCollections, setAllProjectCollections] = useState<
    ProjectCollectionInfo[]
  >([]);

  const [searchText, setSearchText] = useState<string>("");

  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      console.log("projectsPageInit");

      const { data: projectCollections } =
        await projectService.getProjectCollectionsInfos();

      if (projectCollections.length === 0) {
        navigate("/expired");
        return;
      }

      setAllProjectCollections(projectCollections);
      setProjectCollections(projectCollections);
    };

    setTimeout(() => {
      pageHelper.setAutoScrollbars();
    }, 1000);

    tooltips.initTooltips();

    init();
  }, [navigate]);

  useEffect(() => {
    let newProjectCollections = [...allProjectCollections];

    if (searchText) {
      newProjectCollections = newProjectCollections.filter(
        (pc) =>
          pc.collectionName.toLowerCase().includes(searchText.toLowerCase()) ||
          pc.projects.filter(
            (p) =>
              p.projectName.toLowerCase().includes(searchText.toLowerCase()) ||
              p.year.toLowerCase().includes(searchText.toLowerCase()) ||
              p.projectCode.toLowerCase().includes(searchText.toLowerCase())
          ).length > 0
      );
    }

    setProjectCollections(newProjectCollections);
  }, [searchText, allProjectCollections]);

  const onRowClick = (projectCollection: ProjectCollectionInfo) => {
    localStorageService.setIdProjectCollection(
      projectCollection.idProjectCollection
    );
    navigate("/");
  };

  const interRowStyle: any = (index: number) => {
    const style = {
      paddingTop: index === 0 ? "0px" : "8px",
    };
    return style;
  };

  let projectCount = 0;
  for (const projectCollection of projectCollections) {
    projectCount += projectCollection.projects.length;
  }

  const onSearchTextChange = (event: any) => {
    const value = event.target.value;
    setSearchText(value);
  };

  return (
    <>
      <NavBar />
      {allProjectCollections.length > 0 && (
        <BoxContent title="Projects">
          <div className="mb-3">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={searchText}
                onChange={onSearchTextChange}
              />
              <button type="submit" className="btn btn-primary">
                Search
              </button>
            </div>
          </div>
          <div className="container-fluid">
            <div className="d-flex justify-content-end">
              <p>{projectCount} projects</p>
            </div>
          </div>
          <table className="table table-hover">
            <thead>
              <tr>
                {/* <th scope="col">ID</th> */}
                <th scope="col">Collection</th>
                <th scope="col">Project</th>
                <th scope="col">Year</th>
                <th scope="col">Project Code</th>
              </tr>
            </thead>
            <tbody>
              {projectCollections.map(
                (projectCollection: ProjectCollectionInfo, collectionIndex) => (
                  <tr
                    key={"projectCollection_" + collectionIndex.toString()}
                    onClick={() => onRowClick(projectCollection)}
                    className="table-click-row"
                  >
                    <td>{projectCollection.collectionName}</td>
                    <td>
                      {projectCollection.projects.map(
                        (project: ProjectInfo, projectIndex) => (
                          <div
                            style={interRowStyle(projectIndex)}
                            key={"projectName_" + projectIndex.toString()}
                          >
                            {project.projectName}
                          </div>
                        )
                      )}
                    </td>
                    <td>
                      {projectCollection.projects.map(
                        (project: ProjectInfo, projectIndex) => (
                          <div
                            style={interRowStyle(projectIndex)}
                            key={"projectYear_" + projectIndex.toString()}
                          >
                            {project.year}
                          </div>
                        )
                      )}
                    </td>
                    <td>
                      {projectCollection.projects.map(
                        (project: ProjectInfo, projectIndex) => (
                          <div
                            style={interRowStyle(projectIndex)}
                            key={"projectCode_" + projectIndex.toString()}
                          >
                            {project.projectCode}
                          </div>
                        )
                      )}
                    </td>
                    {/* <th scope="row">{project.idProject}</th> */}
                    {/* <td>{project.displayName}</td>
                    <td>{project.year}</td>
                    <td>{project.projectName}</td> */}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </BoxContent>
      )}
    </>
  );
};

export default Projects;
