import { useEffect, useState } from "react";
import BoxContent from "../../components/common/BoxContent";
import organizationService from "../../services/organizationService";
import WarningIcon from "@mui/icons-material/Warning";
import OrganizationStat from "../../models/organizationStat";
import dateTimeHelper from "../../util/dateTimeHelper";
import Moment from "react-moment";
import fileService from "../../services/fileService";
import userService from "../../services/userService";
import { useNavigate } from "react-router-dom";

const Admin: React.FC = () => {
  const navigate = useNavigate();

  const [liveUsersCount, setLiveUsersCount] = useState<number>();

  const [organizationStats, setOrganizationStats] = useState<
    OrganizationStat[]
  >([]);
  const [allOrganizationStats, setAllOrganizationStats] = useState<
    OrganizationStat[]
  >([]);

  const [searchText, setSearchText] = useState<string>("");

  const [isFilterByNoUsers, setIsFilterByNoUsers] = useState<boolean>(false);
  const [isFilterByWithUsers, setIsFilterByWithUsers] =
    useState<boolean>(false);

  const [isFilterByExpired, setIsFilterByExpired] = useState<boolean>(false);
  const [isFilterByNotExpired, setIsFilterByNotExpired] =
    useState<boolean>(false);

  const [isFilterByOffline, setIsFilterByOffline] = useState<boolean>(false);

  const [isSortByLatestAdded, setIsSortByLatestAdded] =
    useState<boolean>(false);

  useEffect(() => {
    const init = async () => {
      const { data: liveUsers } = await userService.getActiveUsers(
        userService.usersActiveLiveSeconds
      );
      setLiveUsersCount(liveUsers.length);

      const { data: organizationStats } =
        await organizationService.getOrganizationStats();

      setAllOrganizationStats(organizationStats);
      setOrganizationStats(organizationStats);
    };

    init();
  }, []);

  const exportOrganizationStats = async () => {
    const response = await organizationService.getOrganizationStatsCsvFile();
    fileService.exportFile(response);
  };

  const exportOrganizationUserStats = async () => {
    const response =
      await organizationService.getOrganizationUserStatsCsvFile();
    fileService.exportFile(response);
  };

  const onEditOrganization = (idOrganization: number) => {
    navigate(`/admin/organizations/${idOrganization}`);
  };

  const setIsFilterByNoUsersCheckboxChecked = (event: any) => {
    const checked: boolean = event.target.checked;
    setIsFilterByNoUsers(checked);

    setIsFilterByWithUsers(false);
  };

  const setIsFilterByWithUsersCheckboxChecked = (event: any) => {
    const checked: boolean = event.target.checked;
    setIsFilterByWithUsers(checked);

    setIsFilterByNoUsers(false);
  };

  const setIsFilterByExpiredCheckboxChecked = (event: any) => {
    const checked: boolean = event.target.checked;
    setIsFilterByExpired(checked);

    setIsFilterByNotExpired(false);
  };

  const setIsFilterByNotExpiredCheckboxChecked = (event: any) => {
    const checked: boolean = event.target.checked;
    setIsFilterByNotExpired(checked);

    setIsFilterByExpired(false);
  };

  const setIsFilterByOfflineCheckboxChecked = (event: any) => {
    const checked: boolean = event.target.checked;
    setIsFilterByOffline(checked);
  };

  useEffect(() => {
    const filterandSortOrgianizationStats = () => {
      let newOrganizationStats = [...allOrganizationStats];

      if (isFilterByNoUsers) {
        newOrganizationStats = newOrganizationStats.filter(
          (o) => o.usersCount === 0
        );
      }

      if (isFilterByWithUsers) {
        newOrganizationStats = newOrganizationStats.filter(
          (o) => o.usersCount > 0
        );
      }

      if (isFilterByExpired) {
        newOrganizationStats = newOrganizationStats.filter((o) =>
          isOrganizationExpired(o)
        );
      }

      if (isFilterByNotExpired) {
        newOrganizationStats = newOrganizationStats.filter(
          (o) => !isOrganizationExpired(o)
        );
      }

      if (isFilterByOffline) {
        newOrganizationStats = newOrganizationStats.filter(
          (o) => o.offlineProjectsCount > 0
        );
      }

      if (searchText) {
        newOrganizationStats = newOrganizationStats.filter((o) =>
          o.organizationName.toLowerCase().includes(searchText.toLowerCase())
        );
      }

      if (isSortByLatestAdded) {
        newOrganizationStats.sort(
          (a: OrganizationStat, b: OrganizationStat) => {
            if (a.goLiveDate === undefined || b.goLiveDate === undefined) {
              return 0;
            }

            const isAsc = true;

            if (isAsc) {
              if (a.goLiveDate > b.goLiveDate) {
                return -1;
              }
              if (b.goLiveDate > a.goLiveDate) {
                return 1;
              }
            } else {
              if (b.goLiveDate > a.goLiveDate) {
                return -1;
              }
              if (a.goLiveDate > b.goLiveDate) {
                return 1;
              }
            }

            return 0;
          }
        );
      }

      setOrganizationStats(newOrganizationStats);
    };

    filterandSortOrgianizationStats();
  }, [
    isFilterByNoUsers,
    isFilterByWithUsers,
    isFilterByExpired,
    isFilterByNotExpired,
    isFilterByOffline,
    searchText,
    isSortByLatestAdded,
    allOrganizationStats,
  ]);

  const isOrganizationExpired = (organizationStat: OrganizationStat) => {
    return (
      organizationStat.subscriptionRenewalDate &&
      dateTimeHelper.dateDiffNowDays(organizationStat.subscriptionRenewalDate) <
        0
    );
  };

  const onSearchTextChange = (event: any) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const setIsSortByLatestAddedCheckboxChecked = (event: any) => {
    const checked: boolean = event.target.checked;
    setIsSortByLatestAdded(checked);
  };

  return (
    <BoxContent title="Dashboard" useFullWidth>
      <div>
        <div className="container-fluid">
          <div className="mb-3 d-flex justify-content-between">
            <div>
              Live Users{" "}
              <span className="text-lg text-heading fw-bold">
                {liveUsersCount}
              </span>
            </div>
            <div>
              Organizations{" "}
              <span className="text-lg text-heading fw-bold">
                {organizationStats.length}
              </span>
            </div>
          </div>
          <div className="mb-3">
            <span className="link" onClick={exportOrganizationStats}>
              Export Organization Stats
            </span>
            <span
              className="link"
              style={{ marginLeft: "15px" }}
              onClick={exportOrganizationUserStats}
            >
              Export Organization User Stats
            </span>
          </div>
          <div className="mb-3">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={searchText}
                onChange={onSearchTextChange}
              />
              <button type="submit" className="btn btn-primary">
                Search
              </button>
            </div>
          </div>
          <div className="mb-2 d-flex align-items-center">
            <div className="me-2">Filter</div>
            <div className="btn-group me-3">
              <input
                type="checkbox"
                checked={isFilterByNoUsers}
                onChange={(e) => setIsFilterByNoUsersCheckboxChecked(e)}
                className="btn-check"
                id="btncheck_isFilterByNoUsers"
                autoComplete="off"
              />
              <label
                className="btn btn-outline-primary btn-sm"
                htmlFor="btncheck_isFilterByNoUsers"
              >
                No Users
              </label>
              <input
                type="checkbox"
                checked={isFilterByWithUsers}
                onChange={(e) => setIsFilterByWithUsersCheckboxChecked(e)}
                className="btn-check"
                id="btncheck_isFilterByWithUsers"
                autoComplete="off"
              />
              <label
                className="btn btn-outline-primary btn-sm"
                htmlFor="btncheck_isFilterByWithUsers"
              >
                With Users
              </label>
            </div>
            <div className="btn-group me-3">
              <input
                type="checkbox"
                checked={isFilterByExpired}
                onChange={(e) => setIsFilterByExpiredCheckboxChecked(e)}
                className="btn-check"
                id="btncheck_isFilterByExpired"
                autoComplete="off"
              />
              <label
                className="btn btn-outline-primary btn-sm"
                htmlFor="btncheck_isFilterByExpired"
              >
                Expired
              </label>
              <input
                type="checkbox"
                checked={isFilterByNotExpired}
                onChange={(e) => setIsFilterByNotExpiredCheckboxChecked(e)}
                className="btn-check"
                id="btncheck_isFilterByNotExpired"
                autoComplete="off"
              />
              <label
                className="btn btn-outline-primary btn-sm"
                htmlFor="btncheck_isFilterByNotExpired"
              >
                Not Expired
              </label>
            </div>
            <div className="me-2">
              <div>
                <input
                  type="checkbox"
                  checked={isFilterByOffline}
                  onChange={(e) => setIsFilterByOfflineCheckboxChecked(e)}
                  className="btn-check"
                  id="btncheck_isFilterByOffline"
                  autoComplete="off"
                />
                <label
                  className="btn btn-outline-primary btn-sm"
                  htmlFor="btncheck_isFilterByOffline"
                >
                  Offline
                </label>
              </div>
            </div>
            <div className="ms-2 me-2 d-flex align-items-center">
              <div className="me-2">Sort</div>
              <input
                type="checkbox"
                checked={isSortByLatestAdded}
                onChange={(e) => setIsSortByLatestAddedCheckboxChecked(e)}
                className="btn-check"
                id="btncheck_IsSortByLatestAdded"
                autoComplete="off"
              />
              <label
                className="btn btn-outline-primary btn-sm"
                htmlFor="btncheck_IsSortByLatestAdded"
              >
                Latest Added
              </label>
            </div>
          </div>
          <div className="row">
            {organizationStats.map((organizationStat, i) => (
              <div
                className="col-md-3"
                style={{ padding: "5px" }}
                key={`organizationStat_${i}`}
              >
                <div
                  className="card"
                  style={{
                    boxShadow: "0px 3px 3px -1px rgba(10,22,70,.04)",
                    borderRadius: ".75rem",
                    visibility:
                      organizationStat.idOrganization !== 0
                        ? "visible"
                        : "hidden",
                  }}
                >
                  <div className="card-body">
                    <div className="d-flex mb-1">
                      <span
                        className="link"
                        onClick={() =>
                          onEditOrganization(organizationStat.idOrganization)
                        }
                      >
                        {organizationStat.organizationName}
                      </span>
                      {organizationStat.usersCount === 0 && (
                        <WarningIcon
                          style={{
                            color: "orange",
                            marginLeft: "4px",
                            fontSize: "20px",
                          }}
                        />
                      )}
                      {isOrganizationExpired(organizationStat) && (
                        <span
                          style={{ marginLeft: "5px" }}
                          className="badge bg-danger"
                        >
                          Expired
                        </span>
                      )}
                      {organizationStat.offlineProjectsCount > 0 && (
                        <span
                          style={{ marginLeft: "5px" }}
                          className="badge bg-secondary"
                        >
                          Offline
                          {` ${organizationStat.offlineProjectsCount} / ${organizationStat.projectsCount}`}
                        </span>
                      )}
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="me-2">Users</div>
                      <div className="text-lg text-heading fw-bold">
                        {organizationStat.usersCount}
                      </div>
                      {organizationStat.usersCount === 0 && (
                        <WarningIcon
                          style={{
                            color: "orange",
                            marginLeft: "4px",
                            fontSize: "20px",
                          }}
                        />
                      )}
                    </div>
                    <div>
                      Go Live Date
                      <span className="text-lg text-heading fw-bold ms-2">
                        {organizationStat.goLiveDate && (
                          <Moment format={dateTimeHelper.dateFormat}>
                            {organizationStat.goLiveDate}
                          </Moment>
                        )}
                        {!organizationStat.goLiveDate && "-"}
                      </span>
                    </div>
                    <div>
                      Live Days
                      <span className="text-lg text-heading fw-bold ms-2">
                        {organizationStat.goLiveDate
                          ? Math.abs(
                              dateTimeHelper.dateDiffNowDays(
                                organizationStat.goLiveDate
                              )
                            )
                          : "-"}
                      </span>
                    </div>
                    <div>
                      Is Paid Subscription
                      <span className="text-lg text-heading fw-bold ms-2">
                        {organizationStat.isPaidSubscription ? "True" : "False"}
                      </span>
                    </div>
                    <div>
                      Subscription Renewal Date
                      <span className="text-lg text-heading fw-bold ms-2">
                        {organizationStat.subscriptionRenewalDate && (
                          <Moment format={dateTimeHelper.dateFormat}>
                            {organizationStat.subscriptionRenewalDate}
                          </Moment>
                        )}
                        {!organizationStat.subscriptionRenewalDate && "-"}
                      </span>
                    </div>
                    <div>
                      Subscription Time Remaining (Days)
                      <span className="text-lg text-heading fw-bold ms-2">
                        {organizationStat.subscriptionRenewalDate
                          ? dateTimeHelper.dateDiffNowDays(
                              organizationStat.subscriptionRenewalDate
                            )
                          : "-"}
                        {organizationStat.subscriptionRenewalDate &&
                          dateTimeHelper.dateDiffNowDays(
                            organizationStat.subscriptionRenewalDate
                          ) < 0 && (
                            <WarningIcon
                              style={{
                                color: "red",
                                marginLeft: "4px",
                                fontSize: "20px",
                              }}
                            />
                          )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </BoxContent>
  );
};

export default Admin;
