import BoxContent from "../components/common/BoxContent";
import NavBar from "../components/navbar/NavBar";

const Expired: React.FC = () => {
  return (
    <>
      <NavBar />
      <BoxContent title="Expired">
        <div>
          Instance has expired. Please contact{" "}
          <a
            className="link"
            href="https://icc-ims.com/company/contact-us"
            target="_blank"
            rel="noreferrer"
          >
            ICC.
          </a>
        </div>
      </BoxContent>
    </>
  );
};

export default Expired;
