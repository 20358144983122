import { useEffect, useState } from "react";
import Organization from "../../models/organization";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import AdminBoxConent from "../../components/admin/AdminBoxContent";
import ProjectCollection from "../../models/projectCollection";
import projectService from "../../services/projectService";
import Project from "../../models/project";

const Projects: React.FC<{ organization: Organization | undefined }> = ({
  organization,
}) => {
  const navigate = useNavigate();

  const [projectCollections, setProjectCollections] = useState<
    ProjectCollection[]
  >([]);

  // const [isAddNew2Enabled, setIsAddNew2Enabled] = useState<boolean>(true);

  useEffect(() => {
    if (!organization) {
      return;
    }

    init(organization);
  }, [organization]);

  const init = async (organization: Organization) => {
    const { data: projectCollections } =
      await projectService.getProjectCollections(organization.idOrganization);
    setProjectCollections(projectCollections);

    // const isAddNew2Enabled = projectCollections.length !== 0;
    // setIsAddNew2Enabled(isAddNew2Enabled);
  };

  const onNewProjectCollection = () => {
    navigate("/admin/project-collections/new");
  };

  const onEditProjectCollection = (projectCollection: ProjectCollection) => {
    navigate(
      `/admin/project-collections/${projectCollection.idProjectCollection}`
    );
  };

  const onNewProject = () => {
    navigate("/admin/projects/new");
  };

  const onEditProject = (project: Project) => {
    navigate(`/admin/projects/${project.idProject}`);
  };

  let projectCount = 0;
  for (const projectCollection of projectCollections) {
    projectCount += projectCollection.projects.length;
  }

  //const hasAddNew = organizationService.isIccOrganization(organization);
  const hasAddNew = false;

  return (
    <AdminBoxConent
      title="Projects"
      hasAddNew={hasAddNew}
      addNewTitle="Add New Project Collection"
      onAddNew={() => onNewProjectCollection()}
      addNew2Title="Add New Project"
      onAddNew2={() => onNewProject()}
      // isAddNew2Enabled={isAddNew2Enabled}
      isAddNew2Enabled={false}
      count={projectCount}
      countType="projects"
      hasCurrentOrganizationDropdown={true}
    >
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Collection</th>
            <th scope="col">Project</th>
            <th scope="col">Year</th>
            <th scope="col">Project Code</th>
            <th scope="col">Organization</th>
            <th scope="col">Has Custom Default Layout</th>
            <th scope="col">Database</th>
            <th scope="col">Report</th>
            <th scope="col">Is Online</th>
            <th style={{ width: "50px" }} />
          </tr>
        </thead>
        <tbody>
          {projectCollections.map(
            (projectCollection: ProjectCollection, collectionIndex) => {
              if (projectCollection.projects.length === 0) {
                return (
                  <tr
                    key={
                      "projectCollection_project" + collectionIndex.toString()
                    }
                  >
                    <td>
                      <div className="d-flex">
                        <div>{projectCollection.collectionName}</div>
                        <div style={{ marginLeft: "5px" }}>
                          <EditIcon
                            style={{ cursor: "pointer" }}
                            fontSize="small"
                            onClick={() =>
                              onEditProjectCollection(projectCollection)
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td />
                    <td />
                    <td />
                    <td>
                      <div key={"organization_" + collectionIndex.toString()}>
                        {projectCollection.organizations
                          .map((o) => o.organizationName)
                          .join(", ")}
                      </div>
                    </td>
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                );
              } else {
                return projectCollection.projects.map(
                  (project: Project, projectIndex) => (
                    <tr
                      key={
                        "projectCollection_project" +
                        collectionIndex.toString() +
                        projectIndex.toString()
                      }
                    >
                      <td>
                        {projectIndex === 0 && (
                          <div className="d-flex">
                            <div>{projectCollection.collectionName}</div>
                            <div style={{ marginLeft: "5px" }}>
                              <EditIcon
                                style={{ cursor: "pointer" }}
                                fontSize="small"
                                onClick={() =>
                                  onEditProjectCollection(projectCollection)
                                }
                              />
                            </div>
                          </div>
                        )}
                      </td>
                      <td>
                        <div key={"projectName_" + projectIndex.toString()}>
                          {project.projectName}
                        </div>
                      </td>
                      <td>
                        <div key={"projectYear_" + projectIndex.toString()}>
                          {project.year}
                        </div>
                      </td>
                      <td>
                        <div key={"projectCode_" + projectIndex.toString()}>
                          {project.projectCode}
                        </div>
                      </td>
                      <td>
                        <div key={"organization_" + projectIndex.toString()}>
                          {projectCollection.organizations
                            .map((o) => o.organizationName)
                            .join(", ")}
                        </div>
                      </td>
                      <td>
                        <div
                          key={
                            "defaultWorkspaceLayout_" + projectIndex.toString()
                          }
                        >
                          {project.defaultWorkspaceLayout ? "True" : "False"}
                        </div>
                      </td>
                      <td>
                        <div key={"databaseName_" + projectIndex.toString()}>
                          {project.databaseName}
                        </div>
                      </td>
                      <td>
                        <div key={"reportName_" + projectIndex.toString()}>
                          {project.reportName}
                        </div>
                      </td>
                      <td>
                        <div key={"isOnline_" + projectIndex.toString()}>
                          {project.isOnline ? "True" : "False"}
                        </div>
                      </td>
                      <td>
                        <div key={"projectEdit_" + projectIndex.toString()}>
                          <EditIcon
                            style={{ cursor: "pointer", marginRight: "10px" }}
                            fontSize="small"
                            onClick={() => onEditProject(project)}
                          />
                        </div>
                      </td>
                    </tr>
                  )
                );
              }
            }
          )}
        </tbody>
      </table>
    </AdminBoxConent>
  );
};
export default Projects;
